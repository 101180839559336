import React, { useState } from "react"
import * as PropTypes from "prop-types"
/** @jsx jsx */
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"

import { Formik, Form, Field, ErrorMessage } from "formik"

const Box = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ModalForm = props => {
  const [isModalActive, setIsModalActive] = useState(false)
  const { packageName, packageType } = props

  const closeModal = () => {
    setIsModalActive(!isModalActive)
    let formId = document.getElementById("SendRequestForm")
    formId.reset()
  }

  return (
    <section>
      <div
        css={{
          marginTop: 40,
          textAlign: `center`,
        }}
      >
        <button
          to="/"
          onClick={() => setIsModalActive(!isModalActive)}
          className="button is-link"
        >
          <FormattedMessage id="SendRequest" />
        </button>
      </div>
      <div className={`modal ${isModalActive ? "is-active" : ""}`}>
        <div className="modal-background" />
        <div className="modal-content section">
          <Box>
            <h4
              css={{
                fontSize: 22,
                marginBottom: 22,
              }}
              className={`primary-title`}
            >
              <FormattedMessage id="SendRequest" />
            </h4>
            <Formik
              initialValues={{
                packageName: `${packageName} | ${packageType}`,
                email: "",
                comments: "",
              }}
              validate={values => {
                let errors = {}
                if (!values.email) {
                  errors.email = "Field Required"
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address"
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                console.log({ values })
                fetch("/.netlify/functions/sendEmail", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(values),
                })
                  .then(response => response.json())
                  .then(myJson => {
                    closeModal()
                    setSubmitting(false)
                    alert(myJson)
                  })

                // fetch("/?no-cache=1", {
                //   method: "POST",
                //   headers: {
                //     "Content-Type": "application/x-www-form-urlencoded",
                //   },
                //   body: encode({
                //     "form-name": "sendrequest",
                //     ...values,
                //   }),
                // })
                //   .then(() => {
                //     alert("Your message was sent successfully")
                //     closeModal()
                //     setSubmitting(false)
                //   })
                //   .catch(error => {
                //     alert("Error: Please Try Again!")
                //     setSubmitting(false)
                //   })
              }}
            >
              {({ isSubmitting, formProps }) => (
                <Form
                  name={`sendrequest`}
                  id={`SendRequestForm`}
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <div className="field">
                    <label className="label">
                      <FormattedMessage id="PackageName" />
                    </label>
                    <div className="control">
                      <Field
                        disabled
                        className={`input is-rounded`}
                        type="text"
                        value={`${packageName} | ${packageType}`}
                        name="packageName"
                        id="packageName"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">
                      <FormattedMessage id="EmailAddres" />
                    </label>
                    <div className="control">
                      <Field
                        className={`input is-rounded`}
                        type="email"
                        placeholder="mimail@host.com"
                        name="email"
                        id="email"
                      />
                      <ErrorMessage
                        className="has-text-danger"
                        name="email"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">
                      <FormattedMessage id="Comments" />
                    </label>
                    <div className="control">
                      <Field
                        className={`textarea`}
                        component="textarea"
                        name="comments"
                        id="comments"
                      />
                    </div>
                  </div>

                  <button
                    css={{
                      marginTop: 20,
                    }}
                    type="submit"
                    className="button is-link"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="SendMessage" />
                  </button>
                </Form>
              )}
            </Formik>
          </Box>
        </div>
        <button
          onClick={closeModal}
          className="modal-close is-large"
          aria-label="close"
        />
      </div>
    </section>
  )
}

ModalForm.propTypes = {
  packageName: PropTypes.string.isRequired,
  packageType: PropTypes.string.isRequired,
}

export default ModalForm

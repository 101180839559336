import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ModalForm from "../components/ModalForm"
import TravelItem from "../components/travel-item/travel-item"

import Section from "../components/section"
import BgMap from "../components/BgMap"
import getTravelTypeColor from "../utils/getTypeColor"

/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

import { rgba } from "polished"

import { animated, useSpring, config } from "react-spring"

import lang from "../data/languages"

import { Parallax } from "react-scroll-parallax"
import Slider from "react-slick"
import { mediaQueries } from "../utils/tokens"

import { colors } from "../utils/tokens"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const propTypes = {
  data: PropTypes.object.isRequired,
}

const TravelsTypeTemplate = props => {
  const travel = props.data.contentfulTravels
  const {
    title,
    seo,
    photo,
    description,
    shortDescription,
    gallery,
    travelType,
    details,
    isHotDeals,
    price,
    dealsPrice,
    relatedTours,
    node_locale,
  } = travel

  const currentLang =
    node_locale === lang.defaultLangKey ? `` : `/${node_locale}`

  const titleProps = useSpring({
    delay: 600,
    config: config.slow,
    from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
  })

  const ShortDescProps = useSpring({
    delay: 700,
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const ShortDesc = styled(animated.p)`
    opacity: 1;
  `

  const TravelTitle = styled(animated.h1)`
    marginbottom: 60;
  `

  const settingsGallerySlider = {
    centerMode: true,
    centerPadding: "30px",
    slidesToShow: 3,
    dots: true,
    className: "centerImage",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
        },
      },
    ],
  }
  return (
    <>
      <Layout data={props.data} location={props.location}>
        <Seo
          title={title}
          description={shortDescription}
          packageImageUrl={seo.fluid.src}
        />
        <section className="header-travel-template">
          <div
            className="bg-img"
            css={{
              transition: `filter 0.3s`,
              filter: "brightness(55%)",
              "&:before": {
                background: rgba(getTravelTypeColor(travelType.slug), 0.8),
                opacity: `0.8`,
              },
            }}
          >
            <Parallax y={[-35, 35]} tagOuter="figure">
              <Img alt={title} fluid={photo.fluid} />
            </Parallax>
          </div>
          <div className="travel-title">
            <TravelTitle style={titleProps} className="primary-title">
              {title}
            </TravelTitle>
            <ShortDesc style={ShortDescProps}>{shortDescription}</ShortDesc>
            {travelType.slug !== "destinations" &&
              (price && (
                <div
                  css={css`
                    color: #fff;
                  `}
                >
                  <div css={{ [mediaQueries.md]: { fontSize: 25 } }}>
                    <span
                      css={css`
                        ${isHotDeals &&
                          `text-decoration: line-through;font-size:13px;`}
                        margin-right: 5px;
                      `}
                    >
                      <FormattedMessage id={`From`} /> $ {price}
                    </span>
                    {dealsPrice && (
                      <span>
                        {" "}
                        <FormattedMessage id={`Now`} /> $ {dealsPrice}
                      </span>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </section>

        {gallery !== null ? (
          <section
            css={{
              position: `relative`,
              textAlign: `center`,
              marginTop: 25,
            }}
          >
            <Slider {...settingsGallerySlider}>
              {gallery.map((item, index) => (
                <div key={index.toString()}>
                  <Img fluid={item.fluid} />
                </div>
              ))}
              <div>
                <Img alt={title} fluid={photo.fluid} />
              </div>
            </Slider>
          </section>
        ) : (
          ``
        )}
        <Section>
          <div className="columns is-centered">
            <div className="column is-8">
              <div
                className="trip-description"
                css={{
                  marginTop: `30px`,
                }}
                dangerouslySetInnerHTML={{
                  __html: `${description.childMarkdownRemark.html}`,
                }}
              />

              {details && (
                <section>
                  <h2
                    className="primary-title"
                    css={{ textAlign: `center`, fontSize: 35 }}
                  >
                    {travelType.slug === "circuits" ? (
                      <FormattedMessage id={`itinerario`} />
                    ) : (
                      <FormattedMessage id={`details`} />
                    )}
                  </h2>
                  <div
                    className="trip-details"
                    css={{
                      marginTop: `30px`,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${details.childMarkdownRemark.html}`,
                    }}
                  />
                </section>
              )}
              {isHotDeals && (
                <div css={{ textAlign: `right` }}>
                  <AniLink
                    direction="right"
                    cover
                    bg={colors.NAVYBLUE}
                    to={`${currentLang}/deals`}
                  >
                    &#8592; <FormattedMessage id="BackToDeals" />
                  </AniLink>
                </div>
              )}

              <div css={{ textAlign: `right` }}>
                <AniLink
                  direction="right"
                  cover
                  bg={colors.NAVYBLUE}
                  to={`${currentLang}/${travelType.slug}`}
                >
                  &#8592; <FormattedMessage id="BackTo" />
                  {travelType.typeTitle}
                </AniLink>
              </div>

              {travelType.slug !== "destinations" && (
                <ModalForm
                  packageName={title}
                  packageType={travelType.typeTitle}
                />
              )}
            </div>
          </div>
        </Section>
        {relatedTours && (
          <BgMap>
            <Section>
              <div
                className="has-text-centered"
                css={{
                  marginBottom: 60,
                }}
              >
                <h2 className="primary-title">
                  <FormattedMessage id="RelatedToursTitle" />
                </h2>
                <FormattedMessage id="RelatedToursQuote" />
              </div>

              <div className="columns">
                {relatedTours.map((node, i) => (
                  <TravelItem node={node} key={i} />
                ))}
              </div>
            </Section>
          </BgMap>
        )}
      </Layout>
    </>
  )
}

TravelsTypeTemplate.propTypes = propTypes

export default TravelsTypeTemplate

export const TravelQuery = graphql`
  query travelQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulTravels(id: { eq: $id }) {
      id
      title
      seo: photo {
        fluid(maxWidth: 1200, maxHeight: 630) {
          src
        }
      }
      photo {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      shortDescription
      description {
        childMarkdownRemark {
          html
        }
      }
      details {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      travelType {
        typeTitle
        slug
      }
      node_locale
      isHotDeals
      price
      dealsPrice
      relatedTours {
        id
        slug
        title
        node_locale
        isHotDeals
        price
        dealsPrice
        travelType {
          slug
          typeTitle
        }
        isActive
        photo {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
